import React, { useContext, useMemo, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { type Organization } from '../../../types/organization';

interface OrgContext {
  org: Organization;
  updateOrg: (data: Partial<Organization>) => void;
}

const context = React.createContext<OrgContext | null>(null);

export const useOrgContext = (): OrgContext => {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No value for OrgContext');
  return ctx;
};

export const useOrg = (): Organization => {
  const { org } = useOrgContext();
  return org;
};

export const OrgProvider = ({
  initOrg,
  children,
  onOrgUpdated,
}: {
  initOrg: Organization;
  children: React.ReactNode;
  onOrgUpdated?: (org: Organization) => void;
}): JSX.Element => {
  const [org, setOrg] = useState(initOrg);

  const updateOrg = useLiveCallback((data: Partial<Organization>) => {
    setOrg((prev) => {
      const updated = {
        ...prev,
        ...data,
      };
      onOrgUpdated?.(updated);
      return updated;
    });
  });

  const ctxValue = useMemo(() => ({ org, updateOrg }), [org, updateOrg]);
  return <context.Provider value={ctxValue}>{children}</context.Provider>;
};
